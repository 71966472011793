var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news"},[_c('nav-bar',{ref:"nav",staticClass:"nav-bar",attrs:{"id":"titleArea"}}),_c('div',{staticClass:"news-content",attrs:{"id":"middleArea"}},[_c('div',{staticClass:"teaNews flex-sb"},[_c('div',{staticClass:"newsLeft"},[_c('div',{staticClass:"top flex-sbac"},[_c('h3',[_vm._v("各地教研")]),_c('router-link',{staticClass:"more",attrs:{"to":"/teachingMore"}},[_vm._v("更多")])],1),_vm._l((_vm.teachNewlist),function(item,index){return _c('div',{staticClass:"box"},[_c('router-link',{staticClass:"row",attrs:{"to":{
                            name: '/teachingDetail',
                            params: {
                                'mdd': _vm.mdd,
                                'id': item.id
                            }
                        }}},[_c('img',{attrs:{"src":item.img}}),_c('div',{staticClass:"des"},[_c('h3',[_vm._v(_vm._s(item.title)),_c('span',{staticStyle:{"float":"right","color":"coral"}},[_vm._v(_vm._s(_vm.formatDate(item.createTime)))])]),_c('p',[_vm._v(_vm._s(item.contents))]),_c('p',{staticStyle:{"text-align":"right"}},[_c('span',[_vm._v("去查看+")])])])])],1)})],2),_c('div',{staticClass:"newsRight",staticStyle:{"display":"none"}},[_c('div',{staticClass:"top flex-sbac"},[_c('h3',[_vm._v("教育大视野")]),_c('router-link',{staticClass:"more",attrs:{"to":"/educationMore"}},[_vm._v("更多")])],1),_c('ul',_vm._l((_vm.educationlist),function(item,index){return _c('li',[_c('router-link',{staticClass:"flex-sbac",attrs:{"to":{
                                name: '/newsDetail',
                                params: {
                                    'id': item.id
                                }
                            }}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('span',{staticClass:"time"},[_vm._v(_vm._s(item.createTime))])])],1)}),0)])]),_c('div',{staticClass:"teaCourse"},[_c('div',{staticClass:"top flex-sbac"},[_c('h3',[_vm._v("名师名课")]),_c('router-link',{staticClass:"more",attrs:{"to":"/teacherMore"}},[_vm._v("更多")])],1),_c('div',{staticClass:"box"},_vm._l((_vm.teachervideolist),function(item,index){return _c('router-link',{staticClass:"row",attrs:{"to":{
                        name: '/videoDetail',
                        params: {
                            'id': item.id
                        }
                    }}},[_c('div',{staticClass:"courseImg"},[_c('img',{staticClass:"t_img",attrs:{"src":item.imgUrl,"alt":""}}),_c('div',{staticClass:"videoBox"}),_c('img',{staticClass:"f_img",attrs:{"src":require("../../assets/img/news/video_button.png"),"alt":""}})]),_c('p',[_vm._v(_vm._s(item.title))])])}),1)])]),_c('bottom-bar',{staticClass:"home-bottom"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }