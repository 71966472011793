<template>
	<div class="news">
		<nav-bar class="nav-bar" id="titleArea" ref="nav"></nav-bar>
		<div class="news-content" id="middleArea">
			<div class="teaNews flex-sb">
				<div class="newsLeft">
					<div class="top flex-sbac">
						<h3>各地教研</h3>
						<router-link to="/teachingMore" class="more">更多</router-link>
					</div>
					<div class="box" v-for="(item, index) in  teachNewlist ">
						<router-link :to="{
                            name: '/teachingDetail',
                            params: {
                                'mdd': mdd,
                                'id': item.id
                            }
                        }" class="row">
							<img :src='item.img'>
							<div class="des">
								<h3>{{ item.title }}<span
										style="float: right;color: coral;">{{formatDate(item.createTime)}}</span>
								</h3>

								<p>{{ item.contents }}</p>
								<p style="text-align: right;"><span>去查看+</span></p>
							</div>
						</router-link>
					</div>
				</div>
				<div class="newsRight" style="display: none;">
					<div class="top flex-sbac">
						<h3>教育大视野</h3>
						<router-link to="/educationMore" class="more">更多</router-link>
					</div>
					<ul>
						<li v-for="( item, index ) in  educationlist ">
							<router-link :to="{
                                name: '/newsDetail',
                                params: {
                                    'id': item.id
                                }
                            }" class="flex-sbac">
								<div class="title">{{ item.title }}</div>
								<span class="time">{{ item.createTime }}</span>
							</router-link>
						</li>
					</ul>
				</div>
			</div>
			<div class="teaCourse">
				<div class="top flex-sbac">
					<h3>名师名课</h3>
					<router-link to="/teacherMore" class="more">更多</router-link>
				</div>
				<div class="box">
					<router-link v-for="( item, index ) in  teachervideolist " class="row" :to="{
                        name: '/videoDetail',
                        params: {
                            'id': item.id
                        }
                    }">
						<div class="courseImg">
							<img :src='item.imgUrl' alt="" class="t_img">
							<div class="videoBox"></div>
							<img src="../../assets/img/news/video_button.png" alt="" class="f_img">
						</div>
						<p>{{ item.title }}</p>
					</router-link>
				</div>
			</div>
		</div>
		<bottom-bar class="home-bottom" />
	</div>
</template>
<script>
	import {
		Message,
		MessageBox
	} from 'element-ui' //导入element-ui组件库
	import {
		PageList,
		PageTeachNewList,
		PageTeacherVideoList
	} from '@/api/news'
	import {
		getInfo
	} from '@/api/login'

	import NavBar from '../../components/content/navBar/NavBar'
	import BottomBar from '../../components/content/bottomBar/BottomBar'
	import {
		getToken,
		setToken,
		removeToken
	} from '@/utils/cookies.js'

	export default {
		name: 'News',
		components: {
			NavBar,
			BottomBar
		},
		data() {
			return {
				users: {}, //用户信息
				mdd: 0,
				/*各地教研*/
				teachNewPage: 1, //初始页
				teachNewSize: 2, //每页的数据
				teachNewQuery: {
					pageIndex: this.teachNewPage,
					pageSize: this.teachNewSize
				},
				teachNewlist: [],
				/*教育大视野*/
				educationPage: 1, //初始页
				educationSize: 6, //每页的数据
				educationQuery: {
					pageIndex: this.educationPage,
					pageSize: this.educationSize,
					newsTypeId: 5
				},
				educationlist: [],
				/*名师名课*/
				teachervideoPage: 1, //初始页
				teachervideoSize: 8, //每页的数据
				teachervideoQuery: {
					pageIndex: this.teachervideoPage,
					pageSize: this.teachervideoSize
				},
				teachervideolist: [],
			}
		},
		created() {
			//用户信息
			this.userInfo();
			//各地教研列表
			this.onPageTeachNewList();
			//教育大视野列表
			this.onPageList();
			//名师名课列表
			this.onPageTeacherVideoList();
		},
		methods: {
			formatDate(nDate) {
				const date = new Date(nDate)
				let fmt ="yyyy-MM-dd"
				let ret
						const opt = {
							'y+': date.getFullYear().toString(), // 年
							'M+': (date.getMonth() + 1).toString(), // 月
							'd+': date.getDate().toString(), // 日
							'H+': date.getHours().toString(), // 时
							'm+': date.getMinutes().toString(), // 分
							's+': date.getSeconds().toString(), // 秒
							// 有其他格式化字符需求可以继续添加，必须转化成字符串
						}
						for (let k in opt) {
							ret = new RegExp('(' + k + ')').exec(fmt)
							if (ret) {
								fmt = fmt.replace(
									ret[1],
									ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
								)
							}
						}
						return fmt
			},
			//各地教研列表
			onPageTeachNewList() {
				this.listLoading = true;
				this.teachNewQuery.pageIndex = this.teachNewPage;
				this.teachNewQuery.pageSize = this.teachNewSize;
				PageTeachNewList(this.teachNewQuery).then(response => {
					if (response) {
						if (response.status == 200) {
							this.teachNewlist = response.data.list;
						} else {
							MessageBox.alert(response.message, "错误", {
								confirmButtonText: '确定',
								type: 'error'
							})
						}
						setTimeout(() => {
							this.listLoading = false
						}, 1.5 * 1000)
					}
				})
			},
			//各地教研点击
			teachingMoreView(id) {
				this.$router.push({
					name: '/teachingDetail',
					params: {
						"id": id,
						"mdd": this.$refs.nav.mdd
					}
				});
			},
			//教育大视野列表
			onPageList() {
				this.listLoading = true;
				this.educationQuery.pageIndex = this.educationPage;
				this.educationQuery.pageSize = this.educationSize;
				PageList(this.educationQuery).then(response => {
					if (response) {
						if (response.status == 200) {
							this.educationlist = response.data.list;
						} else {
							MessageBox.alert(response.message, "错误", {
								confirmButtonText: '确定',
								type: 'error'
							})
						}
						setTimeout(() => {
							this.listLoading = false
						}, 1.5 * 1000)
					}
				})
			},
			//名师名课列表
			onPageTeacherVideoList() {
				this.listLoading = true;
				this.teachervideoQuery.pageIndex = this.teachervideoPage;
				this.teachervideoQuery.pageSize = this.teachervideoSize;
				PageTeacherVideoList(this.teachervideoQuery).then(response => {
					if (response) {
						if (response.status == 200) {
							this.teachervideolist = response.data.list;
						} else {
							MessageBox.alert(response.message, "错误", {
								confirmButtonText: '确定',
								type: 'error'
							})
						}
						setTimeout(() => {
							this.listLoading = false
						}, 1.5 * 1000)
					}
				})
			},
			//用户信息
			userInfo() {
				var token = getToken();
				if (token) {
					getInfo(token).then(response => {
						if (response.status == 200) {
							this.users = response.data;
						}
						setTimeout(() => {
							this.listLoading = false
						}, 1.5 * 1000)
					})
				}
			},
			//查看详情
			details(id) {
				this.$router.push({
					name: '/newsDetail',
					params: {
						"id": id,
						"mdd": this.$refs.nav.mdd
					}
				});
			},
			//图片加载
			imgSrc(value) {
				return this.common.imgUrl + value;
			}
		},
		mounted() {
			this.$refs.nav.mdd = 7;
			localStorage.setItem("mdd", 7);
			this.mdd = 7;
			const titleHeight = document.getElementById('titleArea').clientHeight;
			const middleArea = document.getElementById('middleArea');
			const mheight = document.documentElement.clientHeight;
			middleArea.style.minHeight = (Number(mheight) - (Number(titleHeight) + 50)) + 'px';
		},
	}
</script>
<style scoped lang="less">
	.news {
		min-width: 1000px;

		.nav-bar {
			.bar-icon {
				border-radius: 50%;
				vertical-align: -4px;
				margin-right: 7px;
				width: 1.05rem;
				height: 1.05rem;
			}
		}

		.news-content {
			padding-left: 9.1rem;
			padding-right: 9.1rem;
			margin-top: 1.1rem;
			padding-bottom: 1.8rem;

			.teaNews {
				background-color: #fff;

				.top {
					height: 50px;
					line-height: 50px;
					border-bottom: 1px solid #ececec;

					h3 {
						font-size: 18px;
						color: #333;
						font-weight: normal;
						line-height: 50px;
						display: inline-block;
						margin: 0;
						padding-left: 10px;

						&::before {
							content: "";
							display: inline-block;
							width: 4px;
							height: 16px;
							background-color: #469bd7;
							border-radius: 7px;
							position: relative;
							top: 2px;
							margin-right: 8px;
						}
					}

					.more {
						color: #469bd7;
						font-size: 14px;
					}
				}

				ul {
					padding-top: 20px;
					padding-left: 10px;
					padding-bottom: 20px;

					li {
						line-height: 2em;
						font-size: 15px;

						a {
							color: #666;

							&::before {
								display: inline-block;
								content: "";
								width: 4px;
								height: 4px;
								background-color: #73bdf1;
								border-radius: 50%;
								position: relative;
								top: -2px;
								margin-right: 8px;
							}

							&:hover {
								color: #469bd7;
							}

							.title {
								flex: 1;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
							}

							.time {
								display: block;
								width: 150px;
								text-align: right;
								font-size: 13px;
							}
						}
					}
				}

				.newsLeft,
				.newsRight {
					//width: 49%;
					width: 100%;
					padding-right: 20px;
				}

				.newsLeft {
					.box {
						padding-top: 20px;
						padding-left: 12px;

						.row {
							display: flex;
							padding-bottom: 20px;

							img {
								width: 130px;
								height: 80px;
								object-fit: cover;
								margin-right: 15px;
								border-radius: 3px;
							}

							.des {
								display: flex;
								flex-direction: column;
								justify-content: space-between;
								flex: 1;

								h3 {
									font-size: 16px;
									color: #333;
									font-weight: normal;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: nowrap;
									margin: 0;
								}

								p {
									font-size: 14px;
									margin: 0;
									color: #bbb;
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 2;
									-webkit-box-orient: vertical;
									line-height: 1.8em;

									span {
										color: #469bd7;
										margin-left: 20px;
									}
								}
							}
						}
					}
				}
			}

			.flex-sb {
				display: flex;
				justify-content: space-between;
			}

			.flex-sbac {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			.teaCourse {
				margin-top: 20px;
				background-color: #fff;

				&:last-child {
					margin-bottom: 30px;
				}

				.top {
					height: 50px;
					line-height: 50px;
					border-bottom: 1px solid #ececec;
					box-sizing: border-box;

					h3 {
						font-size: 18px;
						color: #333;
						font-weight: normal;
						line-height: 50px;
						display: inline-block;
						margin: 0;
						padding-left: 10px;
						padding-right: 10px;
						border-bottom: 1px solid #469bd7;
						box-sizing: border-box;
						position: relative;
						top: -1px;
					}

					.more {
						color: #469bd7;
						font-size: 16px;
						padding-right: 10px;
					}
				}

				.box {
					padding-top: 20px;
					display: flex;
					flex-wrap: wrap;
					padding-left: 10px;
					padding-right: 10px;

					.row {
						padding-left: 10px;
						padding-right: 10px;
						padding-bottom: 5px;
						width: 25%;
						display: block;

						&:nth-child(4n) {
							margin-right: 0px;
						}

						.courseImg {
							height: 155px;
							position: relative;
							overflow: hidden;
							border-radius: 3px;

							.t_img {
								width: 100%;
								height: 100%;
								border-radius: 3px;
								transition: transform .5s;
							}

							.videoBox {
								position: absolute;
								left: 0;
								top: 0px;
								bottom: 0;
								right: 0;
								background: rgba(0, 0, 0, 0.1);
								border-radius: 3px;
							}

							.f_img {
								position: absolute;
								top: 20px;
								left: 20px;
								display: inline-block;
								width: 48px;
								height: 48px;
							}
						}

						p {
							color: #666;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							margin-top: 10px;
							font-size: 14px;
						}

						&:hover {
							.t_img {
								transform: scale(1.1);
							}

							p {
								color: #469bd7;
							}
						}
					}
				}
			}
		}
	}
</style>
<style lang="less">
	// 手机
	@media(max-width:768px) {
		.news {
			.news-content {
				.teaNews {
					flex-direction: column;

					.newsLeft,
					.newsRight {
						width: 100% !important;
					}
				}

				.teaCourse .box .row {
					width: 49% !important;
				}
			}
		}
	}

	//平板端
	@media(min-width:768px) {
		.news {
			.news-content {
				.teaNews {
					flex-direction: column;

					.newsLeft,
					.newsRight {
						width: 100% !important;
					}
				}

				.teaCourse .box .row {
					width: 49% !important;
				}
			}
		}
	}

	//小屏
	@media(min-width:992px) {
		.news {
			.news-content {
				.teaNews {
					flex-direction: row;

					.newsLeft,
					.newsRight {
						//width: 49% !important;
						width: 100% !important;
					}
				}

				.teaCourse .box .row {
					width: 25% !important;

					.courseImg {
						height: 120px !important;
					}
				}

			}
		}
	}

	//大屏
	@media(min-width:1300px) {
		.news {
			.news-content {
				.teaCourse .box .row {
					.courseImg {
						height: 150px !important;
					}
				}
			}
		}
	}

	@media(min-width:1600px) {
		.news {
			.news-content {
				.teaCourse .box .row {
					.courseImg {
						height: 180px !important;
					}
				}
			}
		}
	}
</style>